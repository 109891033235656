import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class Agents {
  public agents     : any;
  private agentsS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.agents);
  agentsS$          : Observable<any> = this.agentsS.asObservable();

  constructor() {
    if (typeof localStorage != 'undefined') {
        this.agents = JSON.parse(localStorage.getItem('portalAgents'));
        this.agentsS.next(this.agents);
    } else {
        this.agents = null;
        this.agentsS.next(this.agents);
    }
  }

  getAgents() {
    return this.agents;
  }

  setAgents(agents: any) {
    this.agents = agents;
    this.agentsS.next(this.agents);

    localStorage.setItem('portalAgents', JSON.stringify(this.agents));
  }
}
