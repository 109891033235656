import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Injectable, Inject, NgZone } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(
    private transferState: TransferState,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let key = req.url.replace(/[^a-zA-Z0-9]/g, "");
    if(req.body)
      key = (req.url + '/' + JSON.stringify(req.body)).replace(/[^a-zA-Z0-9]/g, "");


    // if (req.method !== 'GET') {
    //   return next.handle(req);
    // }

    if (req.method !== 'GET' && req.method !== 'POST') {
      return next.handle(req);
    }

    const storedResponse: string = this.transferState.get(makeStateKey(key), null);
    this.transferState.remove(makeStateKey(key)); // cached response should be used for the very first time

    if (storedResponse) {
      const response = new HttpResponse({ body: storedResponse, status: 200 });
      return of(response);
    }

    return next.handle(req);
  }
}
