import { Component, OnInit } from '@angular/core';
import { Router, Route, ActivatedRoute } from "@angular/router";
import { AppSettings, AuthenticationService, UserService, HelperService } from '../../services/_services';

declare var google, window;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public admin          : any = { email: '', password: '' }
  public UserLoginKey   : any;
  public loading        : any = false;
  public showErrorAlert : any = false;
  public ErrorMessage   : any = '';

  public animationDone  : any = false;
  public navDateTime    : any;
  public hours          : any;
  public myInterval     : any;
  public authBrowserSub : any;


  constructor(
    private userService           : UserService,
    private authenticationService : AuthenticationService,
    private activatedRoute        : ActivatedRoute,
    private router                : Router,
    private helperService         : HelperService,
  ) {

    this.authBrowserSub = this.authenticationService.isBrowser.subscribe(isBrowser => {
        if (isBrowser) {
            if (this.authenticationService.get_currentUserValue()) { // Redirect to dashboard if already logged in
                this.router.navigate(['/']);
            } else {
                // Get User login key
                this.UserLoginKey = this.authenticationService.get_UserLoginKey();
            }
        } else {
            // console.log("********* Inside Server State ");
        }
      });
  }

  ngOnInit() {
    this.navDateTime = this.convertTZ(new Date(), 'America/Los_Angeles');
    this.hours = this.navDateTime.getHours();
    this.myInterval = setInterval(() => {
      this.navDateTime = this.convertTZ(new Date(), 'America/Los_Angeles');
    }, 20000);
  }
  ngOnDestroy(): void {
    if(this.authBrowserSub) {
      this.authBrowserSub.unsubscribe();
    }
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  ngAfterViewInit() {

  }

  closeAlert() {
    this.showErrorAlert = false;
  }

  login() {
    // Close previous errors
    this.closeAlert();

    // Show Loader
    this.loading = true;

    this.getIpAddress().then(ip => {
        let url  = AppSettings.API_ENDPOINT + 'login';

        if(this.UserLoginKey){
          // console.log("this.UserLoginKey", this.UserLoginKey);

        }
        else {
          this.UserLoginKey = this.randomString(32, '#aA');
        }

        let data = {
          email       : this.admin.email,
          password    : this.admin.password,
          ip_address  : ip,
          browser     : this.getBrowserInfo(),
          login_key   : this.UserLoginKey
        }

        this.helperService.httpPostRequests(url, data)
           .then(resp => {
               let user = resp.user;
               // let user = resp;
                // Hide Loader
                this.loading = false;
              console.log("user", user);

                /* If not error */
                if(!user.error) {
                    this.authenticationService.setCurrentUserValue(user);
                    this.authenticationService.setUserLoginKey(user['login_key']);
                    this.router.navigate(['/']);
                } else {
                    // Show Error Measage
                    // console.log("this.ErrorMessage", this.ErrorMessage);

                    this.showErrorAlert = true;
                    this.ErrorMessage   = user.error;
                }
            },
            error => {
                // console.log("error: ",error);
                if(typeof error.message != "undefined") {
                  this.showErrorAlert = true;
                  this.ErrorMessage =  error.error.message;
                  // console.log("error: ",error.error.message);
                } else {
                    alert(JSON.stringify(error));
                }

                // Hide Loader
                this.loading = false;
            });
    });
  }

  // login2(){
  //   if (this.admin.email == this.adminSky.email && this.admin.password == this.adminSky.password) {
  //       this.authenticationService.setCurrentUserValue(this.adminSky);
  //       this.router.navigate(['/']);
  //       this.loading = false;
  //       this.showErrorAlert = false;
  //   }
  //   else{
  //       this.showErrorAlert = true;
  //       this.loading = false;
  //   }
  // }

  // getIpAddress(): Promise<any> {
  //   let url  = AppSettings.API_ENDPOINT + 'get-client-ip';
  //
  //   return this.helperService.httpGetRequests(url).then(resp => {
  //       return resp;
  //   }).catch(error => {
  //       console.log("error: ", error);
  //   });
  // }

  getIpAddress(): Promise<any> {
    let url  = AppSettings.API_ENDPOINT + 'get-ip';
    let data = {};
    //data.append("action", "get_client_ip");

    return this.helperService.httpPostRequests(url, data).then(resp => {
      console.log('ip --> ', resp)
        return resp;
    }).catch(error => {
        console.log("error: ", error);
    });
  }

  // getAdress(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     navigator.geolocation.getCurrentPosition(resp => {
  //         let pos = {lng: resp.coords.longitude, lat: resp.coords.latitude};
  //         var geocoder = new google.maps.Geocoder;
  //         geocoder.geocode({'location': pos}, function(results, status) {
  //           if (status === 'OK') {
  //             if (results[0]) {
  //                 resolve(results[0].formatted_address);
  //             }
  //           }
  //         });
  //       },
  //       err => {
  //         reject(err);
  //       });
  //   });
  // }

  randomString(length, chars) {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
  }

  geocodeLatLng(pos) {
    var geocoder = new google.maps.Geocoder;
    geocoder.geocode({'location': pos}, function(results, status) {
      if (status === 'OK') {
        if (results[0]) {
            console.log("results[0]: ",results[0]);
        }
      }
    });
  }

  getBrowserInfo() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
     browserName = "Opera";
     fullVersion = nAgt.substring(verOffset+6);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
     browserName = "Microsoft Internet Explorer";
     fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
     fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
     browserName = "Safari";
     fullVersion = nAgt.substring(verOffset+7);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
     fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
              (verOffset=nAgt.lastIndexOf('/')) )
    {
     browserName = nAgt.substring(nameOffset,verOffset);
     fullVersion = nAgt.substring(verOffset+1);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
       fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
       fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
     fullVersion  = ''+parseFloat(navigator.appVersion);
     majorVersion = parseInt(navigator.appVersion,10);
    }

    // console.log("browserName: ",browserName);
    // console.log("fullVersion: ",fullVersion);
    // console.log("majorVersion: ",majorVersion);
    // console.log("navigator.appName: ",navigator.appName);
    // console.log("navigator.userAgent: ",navigator.userAgent);
    return browserName;
  }

}
