import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  public routerSubscription : any;
  public tog_dashboard      : any = false;
  public getstarted         : any = false;
  public tog_both           : any = false;

  constructor(
    public router   : Router,
    public swPush   : SwPush,
    public swUpdate : SwUpdate
  ){
    this.routerSubscription = this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {

        if(val.url.indexOf('dashboard') != -1){
          this.tog_both = false;
          this.tog_dashboard = true;
        }
        else if(val.url.indexOf('getstarted') != -1){
          this.tog_both = true;
          this.tog_dashboard = true;
        }
        else{
          this.tog_both = false;
          this.tog_dashboard = false;
        }


        if(val.url.indexOf('getstarted') != -1)
          this.getstarted = true;
        else
          this.getstarted = false;
       }
    });
  }

  ngOnInit() {
    // console.log('*** application updated!');
    console.log('*** this.swUpdate: ',this.swUpdate );
    if (this.swUpdate.isEnabled) {
      // this.swUpdate.available.subscribe((evt) => {
      //   console.log('service worker updated');
      // });
      //
      // this.swUpdate.checkForUpdate().then(() => {
      //   // noop
      // }).catch((err) => {
      //   console.error('error when checking for update', err);
      // });

      this.swUpdate.available.subscribe(event => {
        console.log('current version is', event.current);
        console.log('available version is', event.available);
      });
      this.swUpdate.activated.subscribe(event => {
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });

      this.swUpdate.available.subscribe(event => {
        this.swUpdate.activateUpdate().then(() => this.updateApp());
      });
    }
  }

  updateApp() {
    document.location.reload();
    console.log("The app is updating right now");
  }
}
