import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSettings } from './app.setting';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";

declare var config: any;

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject : BehaviorSubject<User>;
    // public  currentUser        : Observable<any>;
    public  currentUser        : any;
    public  UserLoginKey       : any;
    public  corsHeaders        : any;

    public isBrowser = new BehaviorSubject<boolean>(null);

    private currentUserS: BehaviorSubject<any> = new BehaviorSubject<any>(this.currentUser);
    currentUserS$: Observable<any> = this.currentUserS.asObservable();

    private UserLoginKeyS: BehaviorSubject<any> = new BehaviorSubject<any>(this.UserLoginKey);
    UserLoginKeyS$: Observable<any> = this.UserLoginKeyS.asObservable();

    constructor(
                  private http                  : HttpClient,
                  private router                : Router,
                  @Inject(PLATFORM_ID) private platformId: any
                ) {
      // Set if its on browser or server
      this.isBrowser.next(isPlatformBrowser(platformId));

      if(typeof localStorage != 'undefined') {
          // Get user data
          if (JSON.parse(localStorage.getItem('currentUser'))) {
            if(typeof JSON.parse(localStorage.getItem('currentUser'))['sessionTimeout'] == "undefined") {
              this.simpleLogout();
            }
            this.currentUser  = JSON.parse(localStorage.getItem('currentUser'))['user'];
            this.currentUserS.next(this.currentUser);

            //check if past expiration date
            let sessionTimeout = JSON.parse(localStorage.getItem('currentUser'))['sessionTimeout'];
            if (new Date(sessionTimeout) < new Date()) {
              this.simpleLogout();
            }
          }

          // Get User login Key
          if (JSON.parse(localStorage.getItem('UserLoginKey'))) {
            this.UserLoginKey = JSON.parse(localStorage.getItem('UserLoginKey'));
          }
      } else {
          this.currentUser = [];
          this.currentUserS.next(this.currentUser);
      }
    }

    public get_currentUserValue(): any {
        return this.currentUser;
    }

    public setCurrentUserValue(user): any {
      let data         = {};
          data['user'] = user;
      let timeout      = new Date();
      timeout.setHours(timeout.getHours() + 12); // 12 hrs of session
      data['sessionTimeout'] = timeout.toString();
      try {
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUser = user;
        this.currentUserS.next(this.currentUser);
      }
      catch (e) {
        console.log(e)
      }
    }

    public get_UserLoginKey(): any {
      if(!this.UserLoginKey) {
      this.UserLoginKey = this.randomString(128, '#aA');
      this.UserLoginKeyS.next(this.UserLoginKey);
    }

    return this.UserLoginKey;
    }

    public setUserLoginKey(key): any {
      try {
        localStorage.setItem('UserLoginKey', JSON.stringify(key));
        this.UserLoginKey = key;
        this.UserLoginKeyS.next(this.UserLoginKey);
      }
      catch (e) {

      }
    }

    login(username, password) {
        return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    simpleLogout() {
      if(typeof localStorage != 'undefined') {
        // Remove From Session
        localStorage.removeItem('currentUser');
        localStorage.removeItem('portalAgents');

        this.currentUser = null;
        this.currentUserS.next(this.currentUser);
      }
    }

    logout(): Promise<any> {
      console.log(this.currentUser);
      let url  = AppSettings.API_ENDPOINT + 'logout';
      this.corsHeaders = {
            headers: new HttpHeaders()
              .set('Content-Type',  'application/json')
              .set('Accept',  'application/json')
              //.set('Authorization',  `Bearer ${this.currentUser.access_token}`)
          }
      return this.http.post(url,{user_id: this.currentUser.id},this.corsHeaders).toPromise()
          .then( resp => {
            // console.log(resp);

              // remove user from local storage and set current user to null
              // localStorage.removeItem('currentUser');
              // this.currentUser = null;

              // Remove From Session
              localStorage.removeItem('currentUser');
              localStorage.removeItem('portalAgents');
              this.currentUser = null;
              this.currentUserS.next(this.currentUser);

              return true;
          })
          .catch(error => {
              console.log("error: ", error);

              // Simple logout on Api Error
              this.simpleLogout();
              this.router.navigate(['/login']);
          });
    }

    randomString(length, chars) {
      var mask = '';
      if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
      if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      if (chars.indexOf('#') > -1) mask += '0123456789';
      if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
      var result = '';
      for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
      return result;
    }
}
