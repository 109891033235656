import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class Getunitdetails {
  public floorValue     : any;
  private floorValueS   : BehaviorSubject<any> = new BehaviorSubject<any>(this.floorValue);
  floorValueS$          : Observable<any> = this.floorValueS.asObservable();

  constructor() {
    if (typeof localStorage != 'undefined') {
        this.floorValue = JSON.parse(localStorage.getItem('floorValue'));
        if(this.floorValue == null)
          this.floorValue = '';

        this.floorValueS.next(this.floorValue);
    } else {
        this.floorValue = '';
        this.floorValueS.next(this.floorValue);
    }
  }

  getUnitValue() {
    return this.floorValue;
  }

  setUnitValue(floorValue: any) {
    this.floorValue = floorValue;
    this.floorValueS.next(this.floorValue);

    localStorage.setItem('floorValue', JSON.stringify(this.floorValue));
  }
}
