import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class UserIdService {

  public userId     = null;

  constructor() {
  }

  getUserId() {
    return this.userId;
  }

  setUserId(data: any) {
    this.userId = data;
  }
}
